'use strict'
/* ================================
* Utils
* ================================ */

import $ from 'jquery'
import ScrollMagic from 'scrollmagic'
import config from './config'
import {} from './vendors/animation.gsap'
import {} from './vendors/ScrollToPlugin.min.js'


/**
 * Log helper
 * @type {Function}
 */
export const log = config.hasDebug
	? window.console.log.bind(window, 'GreenShoot:')
	: function() {};

/**
 * Convert a kebab-case string into a camelCase one
 * @param  {string} string The string to convert
 * @return {string}        The camelCased string
 */
export const camelCase = function(string) {
	return string.toLowerCase().replace(/-(.)/g, (match, group) => {
		return group.toUpperCase()
	})
}



/**
 * Convert a kebab-case string into a PascalCase one
 * @param  {string} string The string to convert
 * @return {string}        The transformed string
 */
export const pascalCase = function(string) {
	// Remove dashes and transform
	// following letter to uppercase
	string = string.toLowerCase()
		.replace(/-(.)/g, (match, group) => {
			return group.toUpperCase()
		})

	// Return string with first letter
	// transformed to uppercase
	return string.charAt(0).toUpperCase() + string.slice(1)
}



/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {number}    wait       Timer
 * @param  {boolean}   immediate  Launch the function immediately
 * @param  {function}  func       The function that needs debounce
 * @return {function}             A function to bind to the event debounced
 */
export const debounce = function(wait, immediate, func) {
	let timeout;

	return function() {
		const context = this;
		const args = arguments;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}



/**
 * Animate scroll for anchor links
 * @return {void}
 */
export const smoothScroll = function(event) {
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
		const $target = $(this.hash);
		if ($target.length) {
			var hash = this.hash;

			TweenLite.to(config.$htmlBody, 0.8, {
				/*scrollTo: {
					y: $target.offset().top
				},*/
				scrollTop : $target.offset().top,
				ease: Expo.easeInOut,
				onComplete : function() {
					window.location.hash = hash;
				}
			})
			return false
		}
	}
}



/**
 * Create an ending event for the event triggered
 * @param  {object} e The triggered event's object
 * @return {undefined}
 */
export const addEndEvent = debounce(200, false, function(e) {
	console.log(`${e.type}End`)
	$(this).trigger(`${e.type}End`)
})



/**
 * Convert degrees to rad
 * @param  {integer} degrees The angle to convert
 * @return {integer}         The converted angle in rad
 */
export const degToRad = (degrees) => {
	return degrees * Math.PI / 180
}



/**
 * Get a random integer in the given interval
 * @param  {integer} min The interval's minimum
 * @param  {integer} max The interval's maximum
 * @return {integer}     A random integer in the given interval
 */
export const getRandomInt = (min, max) => {
	return Math.floor(Math.random() * (max - min)) + min
}



/**
 * Compute all colors for a gradient
 * @param  {object} startColor  An object containing the red, green and blue channels of the first color
 * @param  {object} endColor    An object containing the red, green and blue channels of the last color
 * @param  {number} percent     The position of the color to compute, in percentage
 * @return {object}             An object containing the red, green, blue and hexadecimal info of the computed color
 */
export const makeGradientColor = function(startColor, endColor, percent) {

	var newColor = {};

	function makeChannel(a, b) {
		return (a + Math.round((b-a)*(percent/100)));
	}

	function makeColorPiece(num) {
		num = Math.min(num, 255);   // not more than 255
		num = Math.max(num, 0);     // not less than 0
		var str = num.toString(16);
		if (str.length < 2) {
			str = '0' + str;
		}
		return str;
	}

	newColor.r = makeChannel(startColor.r, endColor.r);
	newColor.g = makeChannel(startColor.g, endColor.g);
	newColor.b = makeChannel(startColor.b, endColor.b);
	newColor.hex = '#' + makeColorPiece(newColor.r) + makeColorPiece(newColor.g) + makeColorPiece(newColor.b);
	return newColor;
};



/**
 * Set gradient on child elements
 * @param {string} container The container of the elements to set the color to
 * @param {object} options   The options of the gradient
 */
export const setGradient = function(container, options) {

	var $containers = $(document).find(container);

	$containers.each(function(i, el) {
		injector($containers.eq(i), '', 'char', '');
		var $items = $containers.eq(i).find(options.selector);
		$items.each(function(i, el) {
			var $this = $(this);
			var percent = i * 100 / $items.length;
			var color = makeGradientColor(options.startColor, options.endColor, percent);
			$this.css({ color: color.hex });
		});
	});
};




export const injector = function(t, splitter, klass, after) {
	var text = t.text()
	, a = text.split(splitter)
	, inject = '';
	if (a.length) {
		$(a).each(function(i, item) {
			inject += '<span class="'+klass+(i+1)+'" aria-hidden="true">'+item+'</span>'+after;
		});
		t.attr('aria-label',text)
		.empty()
		.append(inject)

	}
}



export const parallax = function() {

    var _duration = "200%";
    var _triggerHook = "onEnter";

    // init controller
    var controller = new ScrollMagic.Controller(),
    	scenes = createScenes();

	controller.addScene(scenes);

	var controllerIsEnabled = true;
	config.$window.on('resize', function() {

		if( config.$window.width() < 1024 ) {
			controller.destroy(true);
			controllerIsEnabled = false;
		} else if( controllerIsEnabled == false ) {
			controller = new ScrollMagic.Controller();
			controllerIsEnabled = true;
			controller.addScene(createScenes());
		}
	}).trigger('resize');



    function createScenes() {
		var scenes = [];

	    // search elements
	    $('[data-trigger]').each(function() {

	        // duration
	        var d = $(this).data('duration') !== undefined ? $(this).data('duration') : _duration;

	        // trigger hook
	        var tH = $(this).data('trigger-hook') !== undefined ? $(this).data('trigger-hook') : _triggerHook;

	        var properties = $(this).data('properties');

	        // build scenes
	        var scene = new ScrollMagic.Scene({triggerElement: ($(this).data('trigger') == 'this' ? $(this)[0] : $(this).data('trigger')) })
	                        .setTween($(this), properties)
	                        //.addIndicators()
	                        .duration(d)
	                        .triggerHook(tH)
	                        .addTo(controller);

	        scenes.push(scene);
	    });

	    return scenes;
	}
}
