import Default from './pages/default'
import Home from './pages/home'
import Products from './pages/products'
import Product from './pages/product'
import Marque from './pages/marque'

const routes = {
	Default: Default,
	Home: Home,
	Products: Products,
	Product: Product,
	Marque: Marque
}


export default routes