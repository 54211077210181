import { addEndEvent, smoothScroll, setGradient, parallax } from '../utils'
import cookies from 'js-cookie'
import config from '../config'
import Lazy from '../utils/lazy'
import Reveal from '../vendors/js-reveal'
import $ from 'jquery'
import MicroModal from 'micromodal';
console.log(MicroModal);
export default class Default {

	/**
	 * Constructor function
	 * @return {object} The current instance
	 */
	constructor() {
		console.log(`${this.constructor.name}:constructor`)

		// ask for cookies
		this.cookies();

		// Prevent scrollTop on click on a[href="#"] links
		config.$document.on('click', 'a[href="#"]', function(e) { e.preventDefault(); });

		// Smooth scroll for anchors link
		config.$document.on('click', 'a[href*="#"]:not([href="#"])', smoothScroll);

		// Create throttled events
		config.$window.on({
			resize: addEndEvent,
			scroll: addEndEvent
		});

		// open overlay
		$('.trigger-overlay, .trigger-close-overlay').on('click', function(e) {
			e.preventDefault();
			console.log('click trigger overlay')

			$('.overlay.show').removeClass('show');

			$('.overlay[data-overlay="'+$(this).data('overlay')+'"]').addClass('show');

			config.$htmlBody.toggleClass('open-overlay');

			cookies.set('newsletter-first', 'open', { expires : 365 })
		});

		if (cookies.get('newsletter-first') === undefined || window.location.hash === '#newsletter') {
			$('.trigger-overlay.nl-picto').trigger('click')
		}

		$('.mc4wp-form').bind('DOMSubtreeModified', function(e) {

			if ($(this).find('.mc4wp-success').length) {

				setTimeout(function() {
					$('.trigger-close-overlay').trigger('click')
				}, 3000)
			}
		})

		// open menu
		$('.toggle-menu').on('click', function(e) {
			e.preventDefault();

			config.$html.toggleClass('open-menu');
		})

		// Init js reveal
		var reveal = new Reveal('.js-reveal', {
			successClass: 'is-visible'
		});
		reveal.init();

		// Upload img
		var lazy = Lazy({ onSuccess : function() {
			reveal.update($('.js-reveal'));
		}});
		lazy.init();

		// Js gradient
		setGradient('.js-gradient, .entry-body h2', {
			selector: 'span',
			startColor: { r: 202, g: 124, b: 61 },
			endColor: { r: 255, g: 190, b: 121 }
		});

		// Init parallax
		parallax();

		// Init popin newsletter submit
		$('.mc4wp-form').on('submit', function(e) {
			e.preventDefault();
			var $loader = $(this).find('.ajax-loader'),
				$form = $(this);

			TweenMax.to($loader, 0.2, { autoAlpha : 1 });
			TweenMax.to($('.mc4wp-response'), 0.2, { autoAlpha : 0, onComplete : function() { $('.mc4wp-response').remove() } });

			$.ajax({
				url : '/contact/',
				method : 'POST',
				data : $(this).serialize(),
				success : function(data) {
					TweenMax.to($loader, 0.2, { autoAlpha : 0 });

					$form.append($(data).find('.mc4wp-response').hide().slideDown());
				},
				error : function(xhr, status, error) {
				  var err = eval("(" + xhr.responseText + ")");
				  alert(err.Message);
				}
			});

			return false;
		})

		MicroModal.init();

		if (!localStorage.getItem('modal-typeform')) {

			setTimeout(function() {

				MicroModal.show('modal-typeform');
				config.$html.addClass('open-overlay');

				$('#modal-typeform [data-micromodal-close]').on('click', function(event) {
					if (typeof $(event.target).attr('data-micromodal-close') === 'string') {
						localStorage.setItem('modal-typeform', 'is-close');
						config.$html.removeClass('open-overlay');
					}
				});
			}, 15000);
		}

		// Check if mac for better font aliasing
		if (config.isMac) config.$html.addClass('is-mac')

		return this
	}

	/**
	 * Init the page
	 * @return {object} The current instance
	 */
	init() {
		console.log(`${this.constructor.name} is init`)

		return this;
	}


	/**
	 * Kill the page
	 * @return {object} The current instance
	 */
	kill() {
		console.log(`${this.constructor.name} has been killed`)

		return this;
	}


	/**
	 * Stuff to do on window resize
	 * @param  {integer} width  The current view size
	 * @param  {integer} height The current view height
	 * @return {undefined}
	 */
	resize(width, height) {
		console.log(`${this.constructor.name} has been resized`)
	}


	/**
	 * Handle cookies message
	 * @return {undefined}
	 */
	cookies() {
		const $cookies = $('#js-cookies')

		if (cookies.get('terms') === 'accepted') {
			$cookies.remove()
			return
		}

		$cookies.addClass('is-visible');

		$('#js-cookies__btn').on('click', function() {
			$cookies.remove()
			// Set a cookie that expires in 1 year
			cookies.set('terms', 'accepted', { expires : 365 })
			return false
		});
	}
}
