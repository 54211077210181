import Default from './default'
import $ from 'jquery'
import config from '../config'
import {TweenMax} from 'gsap'

export default class Marque extends Default {

	constructor() {
		super();

		var _ = this;
	}

	init() {

		super.init()

		// toggle contenant
		var currentSeason = 2,
			seasonDirection = '',
			$contenants = $('.contenants'),
			$contenantNav = $('.contenant-nav'),
			$toggle = $contenantNav.find('.toggle'),
			tl = new TimelineMax({ paused : true });

		currentSeason = $('.contenants').hasClass('summer') ? 1 : 2
		seasonDirection = (typeof $contenants.attr('data-season-direction') === 'string' && $contenants.attr('data-season-direction') !== '') ? $contenants.attr('data-season-direction') : 'season1|season2'
		seasonDirection = seasonDirection.replace(/season/g, '')

		tl.to($toggle.find('i'), 0.2, { right : 3, ease : Expo.easeIn })
		  .to($toggle.find('i'), 0.2, { left : 38, ease : Expo.easeOut });

		$toggle.on('click', function(e) {

			if (currentSeason == 2) {
				if (seasonDirection === '1|2') { tl.play(); } else { tl.reverse(); }
				
				currentSeason = 1;
				$contenants.addClass('winter').removeClass('summer');
			} else {
				if (seasonDirection === '1|2') { tl.reverse(); } else { tl.play(); }
				currentSeason = 2;
				$contenants.addClass('summer').removeClass('winter');
			} 
		});

		$contenantNav.on('click', 'a', function(e) {
			e.preventDefault();

			if ($(this).hasClass('season1')) {
				currentSeason = 1;
			} else {
				currentSeason = 2;
			}

			$toggle.trigger('click');
		});
	}
}