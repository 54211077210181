import $ from 'jquery'

const config = {

	PATH: '',

	prevRoute: '/',
	routes: {
		default: '/',
		home: '/'
	},

	$document: $(document),
	$window: $(window),
	$html: $('html'),
	$body: $('body'),
	$htmlBody: $('html, body'),

	width: window.innerWidth,
	height: window.innerHeight,

	hasDebug: window.location.host !== 'www.greenshoot.fr',

	isMobile: window.innerWidth <= 768,
	isMac: navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC'
};

export default config;
