import Default from './default'
import $ from 'jquery'
import config from '../config'
import {TweenMax} from 'gsap'

export default class Product extends Default {

	constructor() {
		super();

		var _ = this;

		config.currentComment = 0;

		// init comment nav
		var $comments = $('.list-comments'),
			$nav = $comments.find('.comments-nav a');

		$nav.on('click', function(e) {
			e.preventDefault(); 
			if( $(this).hasClass('next') ) {
				var next = $comments.find('.col-left .product-comment').length - 1 == config.currentComment ? 0 : config.currentComment + 1;
			} else {
				var next =  config.currentComment == 0 ? $comments.find('.col-left .product-comment').length - 1 : config.currentComment - 1;
			}
			_.gotoComment(next);
		})

		// show the first comments
		_.gotoComment(0);

		// init the hover product declinaison
		$('.decli-item')
			.on('mouseenter', function(e) {
				$(window).trigger('scroll');
				$('.figure-cover[data-num="'+$(this).data('cover')+'"]').addClass('active');
			})
			.on('mouseleave', function(e) {
				$('.figure-cover[data-num="'+$(this).data('cover')+'"]').removeClass('active');
			});

		$(window).trigger('scroll');
	}

	init() {

		super.init()
	}

	gotoComment(index) {
		var $comments = $('.list-comments'),
			$colL = $comments.find('.col-left'),
			$colR = $comments.find('.col-right'),
			$commentsL = $colL.find('.product-comment'),
			$commentsR= $colR.find('.product-comment'),
			direction = 1;
			//direction = index > config.currentComment ? 1 : -1;

		
		TweenMax.to($commentsL.eq(config.currentComment), 0.7, { autoAlpha : 0, x :  direction * -30 + '%', y : direction * -30 + '%', ease : Expo.easeInOut });
		TweenMax.to($commentsR.eq(config.currentComment), 0.7, { autoAlpha : 0, x :  direction * -30 + '%', scale : 0.4, delay : 0.15, ease : Expo.easeInOut });

		// col left
		if( $commentsL.eq(index).length > 0 ) {
			TweenMax.fromTo($commentsL.eq(index), 0.7, { autoAlpha : 0, x : direction * 30 + '%', y : direction * 30 + '%' }, { autoAlpha : 1, x : '0%', y : '0%', delay : 0.2, ease : Expo.easeInOut });
		}

		// col right
		if( $commentsR.eq(index).length > 0 ) {
			TweenMax.fromTo($commentsR.eq(index), 0.7, { autoAlpha : 0, x : direction * 30 + '%', scale : 1.1 }, { autoAlpha : 1, x : '0%', scale : 1, delay : 0.35, ease : Expo.easeInOut });
		}

		config.currentComment = index;
	}
}