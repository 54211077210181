import Default from './default'
import $ from 'jquery'
import Lazy from '../utils/lazy'
import {TweenMax} from 'gsap'

export default class Home extends Default {

	constructor() {

		super();

		var $intro = $('.intro'),
			$figure = $intro.find('figure'),
			$hero = $('.hp-hero'),
			tl = new TimelineMax({ paused : true, onComplete : function() {
				$intro.remove();
				$('.home').addClass('no-intro');
			} });

		setTimeout(function() {
			TweenMax.to($intro.find('footer'), 0.6, { opacity : 1, y : '0%' });	
			tl 	.add(function() { $intro.addClass('start') })
				.to($intro.find('footer'), 0.6, { opacity : 0, y : '50%' }, 'start' )
				.to($figure, 0.8, { top : $hero.offset().top, ease : Power4.easeOut }, 'start' )
				.to($figure, 1.2, { width : $hero.width(), ease : Power4.easeOut }, 'start' )
				.to($figure, 1, { height : $hero.height(), ease : Power4.easeOut}, 'start' );

			// Animation intro
			$intro.find('a').on('click', function() {
				tl.play();
			});

			// Scroll
			$(window).one('mousewheel', function() {
				tl.play();
			})
		}, 500);
	}

	init() {

		super.init()
	}
}