import Default from './default'
import $ from 'jquery'
import Lazy from '../utils/lazy'
import {TweenMax} from 'gsap'

export default class Products extends Default {

	constructor() {

		super();

		$('.trigger-decli').on('click', function(e) {
			e.preventDefault();

			if( $(this).hasClass('active') ) return;

			var $parent = $(this).parents('.product-item'),
				$imgs = $parent.find('.product-img a'),
				$img = $imgs.eq($(this).parent().index()),
				$decliActive = $parent.find('.trigger-decli.active'),
				$links = $parent.find('a:not(.trigger-decli)');

			if( $img.length > 0 ) {
				$parent.find('.product-img a.active').removeClass('active');
				$img.addClass('active');

				$parent.find('.trigger-decli.active').removeClass('active');
				$(this).addClass('active');

				$links.attr('href', $links.attr('href').replace($decliActive.attr('href'), '') + $(this).attr('href'));
			}
		});
	}

	init() {

		super.init()
	}
}